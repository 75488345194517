import React from "react"
import styled from "@emotion/styled"

const PrimaryStyle = styled.button`
  font-family: oswald;
  color: #003357;
  border: none;
  cursor: pointer;
  border-left: 20px solid #b5232b;
  background-color: white;
  padding: 0.5rem 2rem;
  font-size: 26px;
  transform: skewX(-25deg);
  & > span {
    transform: skewX(25deg);
    display: inline-block;
  }
`

export const PrimaryButton = ({ children }) => (
  <PrimaryStyle>
    <span>{children}</span>
  </PrimaryStyle>
)
