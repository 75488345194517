import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import ContentContainer from "../components/contentContainer"
import FluidContainer from "../components/fluidContainer"
import { Header2, Subheading } from "../components/headings"
import { PrimaryButton } from "../components/buttons"
import ReactMarkdown from "react-markdown/with-html"
import SEO from "../components/seo"

const Header = styled.h1`
  font-family: oswald, sans-serif;
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 400;
  @media (max-width: 576px) {
    font-size: 2rem;
  }
  @media (max-width: 450px) {
    font-size: 1rem;
  }
`

const Terms = styled.span`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: darkblue;
  }
`

const FormContainer = styled.div`
  font-family: roboto;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const HeadingContainer = styled.div`
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .text {
    flex: 50%;
  }
  img {
    text-align: center;
    flex: 0 1 40%;
    object-fit: scale-down;
    width: 100%;
  }
  @media (max-width: 768px) {
    display: block;
    text-align: center;
    .text {
      padding: 0;
    }
  }
`

const ContestContainer = styled.div`
  background-color: #eaecec;
  padding: 2rem;
  font-family: roboto;
  font-size: 16px;
  font-weight: 300;
  color: #333;
`

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 600px;
  fieldset {
    border: none;
    flex: 1 0 45%;
    margin: 0.5rem;
  }
  button {
    margin: 2rem auto;
  }
  input {
    padding: 0.5rem;
    width: 100%;
  }
  label {
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0rem 0 1rem;
    display: block;
  }
`
const Input = styled.input`
  border-color: ${props => (props.error ? "red" : "unset")};
`

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

const isRegistrationOpen = registrationOpens => {
  const now = Date.now()
  const opens = new Date(registrationOpens).getTime()
  const isOpen = now > opens
  return isOpen
}

const ContestTemplate = ({
  data: {
    strapiContests: {
      id,
      sn,
      name,
      date,
      winner,
      city,
      state,
      summary,
      registrationOpens,
      forceRegistrationClosed,
      forceRegistrationOpen,
      logo,
    },
  },
}) => {
  const [showForm, toggleForm] = useState(
    !forceRegistrationClosed &&
      ((registrationOpens && isRegistrationOpen(registrationOpens)) ||
        forceRegistrationOpen)
  )
  //Form input state
  const [fullName, setName] = useState("")
  const [email, setEmail] = useState("")
  const [location, setLocation] = useState("")
  const [specialty, setSpecialty] = useState("")
  const [phone, setPhone] = useState("")
  const [age, setAge] = useState("")
  const [social, setSocial] = useState("")
  const [comments, setComments] = useState("")

  const [formErrors, setErrors] = useState({})
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [showTerms, setTerms] = useState(false)

  /**
   * Load Twitter JS
   */
  useEffect(() => {
    const tag = document.createElement("script")
    tag.async = false
    tag.charSet = "utf-8"
    tag.src = "https://platform.twitter.com/widgets.js"
    document.body.appendChild(tag)
  }, [])

  const submitForm = e => {
    e.preventDefault()
    const data = {
      name: fullName.replace(/,/g, ""),
      email,
      location: location.replace(/,/g, ""),
      specialty: specialty.replace(/,/g, ""),
      phone,
      age,
      social: social.replace(/(\r\n|\n|\r)/gm, " ").replace(/,/g, ""),
      comments: comments.replace(/(\r\n|\n|\r)/gm, " ").replace(/,/g, ""),
      contest: name.replace(/,/g, ""),
      sn,
      contestId: id,
    }

    const errors = {
      fullName: false,
      email: false,
      server: false,
    }

    if (!fullName) {
      const nameError = <li>Please provide a name.</li>
      errors.fullName = nameError
    }

    if (!email.match(emailRegex)) {
      const emailError = <li>Please provide a valid e-mail address.</li>
      errors.email = emailError
    }

    if (Object.values(errors).some(e => !!e)) {
      return setErrors(errors)
    }

    fetch(`${process.env.GATSBY_API_URL}/signups`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(data),
    }).then(resp => {
      if (resp.ok) {
        setFormSubmitted(true)
        return toggleForm(false)
      }
      return setErrors({
        server: (
          <li>
            There was an error submitting your request. Please try again later,
            or contact us
          </li>
        ),
      })
    })
  }

  return (
    <Layout>
      <SEO title={`${name}`} keywords={[`eating`, `contests`, `official`]} />
      <FluidContainer>
        <ContentContainer>
          <ContestContainer>
            <HeadingContainer>
              <div className="text">
                <Header>{name}</Header>
                <Header2>
                  Location: {city}, {state}
                  <br />
                  Date: {date}
                  <br />
                  {winner && <span>Winner: {winner}</span>}
                </Header2>
                {!winner && (
                  <div>
                    <div>Who do you think will win? Tell us!</div>
                    <a
                      href="https://twitter.com/intent/tweet?screen_name=eatingcontest&ref_src=twsrc%5Etfw"
                      className="twitter-mention-button"
                      data-size="large"
                      data-related=""
                      data-text={`I think ... will win ${name} on ${date} at ${city}, ${state}! #MLE #MLEtweets #smacktalk`}
                      data-dnt="true"
                      data-show-count="false"
                    >
                      Tweet to @eatingcontest
                    </a>
                  </div>
                )}
                {showForm && (
                  <Subheading>
                    <div>
                      <span role="img" aria-label="party emoji">
                        &#127881;
                      </span>
                      <a style={{ textDecoration: "none" }} href="#form">
                        <em>Sign up now</em>
                      </a>
                      <span aria-label="party emoji" role="img">
                        &#127881;
                      </span>
                    </div>
                  </Subheading>
                )}
              </div>
              {logo && logo.image && <img src={logo.image.url} />}
            </HeadingContainer>
            <ReactMarkdown escapeHtml={false} source={summary} />
          </ContestContainer>
          {showForm && (
            <FormContainer id="form">
              <Header style={{ margin: "2rem", textAlign: "center" }}>
                Registration is open
              </Header>
              <p style={{ textAlign: "center" }}>
                {name}
                <br />
                {city}, {state}
                <br />
                {date}
              </p>
              <Form onSubmit={submitForm}>
                <fieldset>
                  <label htmlFor="name">Full Name*</label>
                  <Input
                    required
                    error={formErrors.fullName}
                    id="name"
                    name="name"
                    type="text"
                    onChange={({ target }) => setName(target.value)}
                    value={fullName}
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="email">E-mail*</label>
                  <Input
                    required
                    error={formErrors.email}
                    id="email"
                    name="email"
                    type="email"
                    onChange={({ target }) => setEmail(target.value)}
                    value={email}
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="location">City/State</label>
                  <input
                    id="location"
                    name="location"
                    type="text"
                    onChange={({ target }) => setLocation(target.value)}
                    value={location}
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="specialty">Specialty</label>
                  <input
                    id="specialty"
                    name="specialty"
                    type="text"
                    onChange={({ target }) => setSpecialty(target.value)}
                    value={specialty}
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="phone">Phone number</label>
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    onChange={({ target }) => setPhone(target.value)}
                    value={phone}
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="age">Age</label>
                  <input
                    id="age"
                    name="age"
                    type="text"
                    onChange={({ target }) => setAge(target.value)}
                    value={age}
                  />
                </fieldset>
                <fieldset style={{ flex: "1 0 100%" }}>
                  <label htmlFor="social">Social media links</label>
                  <textarea
                    id="social"
                    name="social"
                    onChange={({ target }) => setSocial(target.value)}
                    value={social}
                    rows="3"
                    style={{ width: "100%" }}
                  />
                </fieldset>
                <fieldset style={{ flex: "1 0 100%" }}>
                  <label htmlFor="comments">Comments</label>
                  <textarea
                    id="comments"
                    name="comments"
                    onChange={({ target }) => setComments(target.value)}
                    value={comments}
                    rows="3"
                    style={{ width: "100%" }}
                  />
                </fieldset>
                <fieldset style={{ flex: "1 0 100%" }}>
                  <label>
                    I have read and agree to the{" "}
                    <Terms onClick={() => setTerms(true)}>
                      terms and conditions*
                    </Terms>
                  </label>
                  {showTerms && (
                    <p>
                      *All applicants must be 18 years of age or older and fully
                      acknowledge and understand the inherent dangers and risks
                      associated with participating in an eating contest. The
                      applicant further acknowledges and understands that the
                      MLE/IFOCE coordinates with event sponsor(s) to select and
                      approve eligible participants, and that the MLE/IFOCE
                      shall decide, at its sole discretion, whether an applicant
                      is eligible to participate in any eating event, and/or
                      related activities. The MLE/IFOCE may revoke an
                      applicant's eligibility to participate in the eating
                      contest, or related activities, at any time, for any
                      reason, at its sole discretion. The MLE/IFOCE and the
                      event sponsors reserve the right, at any time, to change
                      any and all details concerning the event, including, e.g.,
                      time, duration, location and prize structure.tion,
                      location and prize structure.
                    </p>
                  )}
                  <span>
                    Yes - I have read, and also agree with the terms and
                    conditions. &nbsp;
                    <input style={{ width: "auto" }} required type="checkbox" />
                  </span>
                </fieldset>
                <PrimaryButton type="submit">Submit</PrimaryButton>
              </Form>
              <small>
                <ul>{Object.values(formErrors)}</ul>
              </small>
            </FormContainer>
          )}
          {formSubmitted && (
            <h3 style={{ padding: "2rem" }}>
              We have received your response. We will reach out to you as the
              contest approaches!
            </h3>
          )}
          {forceRegistrationClosed && (
            <Header style={{ margin: "2rem", textAlign: "center" }}>
              Registration is closed.
            </Header>
          )}
        </ContentContainer>
      </FluidContainer>
    </Layout>
  )
}

export default ContestTemplate

export const query = graphql`
  query ContestTemplate($id: String!) {
    strapiContests(id: { eq: $id }) {
      name
      sn
      id
      date(formatString: "MM-DD-YYYY")
      city
      logo {
        image {
          url
        }
      }
      state
      winner
      summary
      registrationOpens
      forceRegistrationClosed
      forceRegistrationOpen
    }
  }
`
